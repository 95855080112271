import React from "react";

function HTTP404() {
  return (
    <main style={{ padding: "1rem" }}>
          <p>404 error</p>
        </main>
  );
}

export default HTTP404;